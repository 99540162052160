/**
 * Styles the footer on all pages
 */

.testimonials {
	@include clamped-width;
	@include full-width {
		background-color: rgba(239,149,0,0.7);
		background-image: url('../images/orange-bg.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}
	padding-top: 80px;
	padding-bottom: 100px;
	
	blockquote {

		padding-left: 60px;
		position: relative;

		@include respond-to(small) {
			padding-left: 50px;
		}

		&:before {
			content: '\f10d' ;
			font-family: fontawesome;
			font-size: 2em;
			color: #1C5BA4;
			position: absolute;
			left: 0;
			padding-left: 10px;
		}
		
		p {
			font-style: italic;
			font-size: 30px;
			line-height: 46px;
			color: #fff;

			@include respond-to(small) {
				font-size: 18px;
				line-height: 1.6em;
			}
		}
		
		cite {
			font-style: normal;
			font-size: 16px;
			font-weight: bold;
			line-height: 46px;
			color: $color-blue;

			&:before {
				content: '- ' ;

			}
		}
	}

	.rotary-icon-yellow {
		width: 100px;
		background-color: #B37601;
		border-radius: 50px;
		padding: 10px;
		position: absolute;
		top: -50px;
		left: calc(50% - 50px);


		img {
			width: 80px;
			height: auto;
		}
	}
}

body > footer {
	text-align: center;
	background-color: $color-blue;
	color: #fff;
	position: relative;

	@include full-width {
		background-color: $color-blue;
	}

	nav {
		ul > li {
			display: inline-block;

			> a {
				display: block;
				padding: 8px 20px;
			}
		}
	}
	
	.colophon {
		padding: 20px 10px;
		text-align: center;

		h2 {
			color: $color-aqua;
			font-weight: bold;
			font-family: 'Open Sans Condensed', sans-serif;
			font-size: 1.4em;
			margin-top: 40px;
			margin-bottom: 20px;
		}
		p {
			color: $color-aqua;
			margin-bottom: 20px;
			font-weight: lighter;
		}
		p.copyright {
			color: #fff;
		}
	}

	.rotary-icon {
		width: 100px;
		background-color: $color-blue ;
		border-radius: 50px;
		padding: 10px;
		position: absolute;
		top: -50px;
		left: calc(50% - 50px);


		img {
			width: 80px;
			height: auto;
		}

	}
}