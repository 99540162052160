table.meeting-location-table {
	background-color: #fff;
	margin-top: 20px;
	padding: 10px 0px;
	display: inline-block;
	border-radius: 10px;
	font-size: 11px;


	tr {

		width: 100%;
		display: inline-block;
		padding: 7px 10px;

		&:nth-child(even) {
			background-color: #FAF9F7;
		}
	}
}