body > .backdrop {
	content: "";
	display: block;
	position: fixed;
	z-index: 50;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: black;
	cursor: pointer;

	transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
	opacity: 0.7;
	visibility: visible;

	&:not([hidden]) {
		transition-delay: 0s;
	}

	&[hidden] {
		display: block;
		opacity: 0;
		visibility: hidden;
	}
}