/**
 * Gecko Designs Reset
 * Based on Eric Meyer's CSS Reset
 */

// Base property resets
html, body, div, span, applet, object, iframe,
button, input, select, textarea,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main, dialog,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	background: none;
	vertical-align: baseline;
	color: inherit;
}

// Box-sizing reset
html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

// Display role reset
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, dialog {
	display: block;
}

// Uniform line height
body {
	line-height: 1;
}

// Clean out default decorations
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
	content: none;
}

// More sane default tables
table {
	border-collapse: collapse;
	border-spacing: 0;
}

// Reset links to be nothing
a {
	color: inherit;
	text-decoration: none;
}

// Reset headers
h1, h2, h3, h4, h5, h6 {
	text-decoration: none;
}