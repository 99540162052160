main.about-us {

	.about-list {

		margin-top: 40px;
		
		ul {
			margin-left: 0px;
			margin-top: 20px;

			li {
				list-style: none;
				color: #5e5951;
				font-weight: bold;
				margin-bottom: 15px;
				display: block;
				text-indent: -22px;
				margin-left: 22px;


				&:before {
					content: '\f058'; 
					font-family: fontawesome;
					color: $color-orange; 
					margin-right: 10px;
				}
			}
		}
	}

	.tab-member-list {
	
		font-size: 11px;

	}

	#page-wrapper {
		.page-content {
			.row {
				img {
					display: none;
				}
			}
		}
	}
	
}

#member-list_wrapper {

	background-color: #fff;

	#member-list_length {
		padding-left: 10px;
	}

	#member-list_filter {
		margin-right: 10px;
	}

	#member-list_info {
		margin-left: 10px;
	}

	#member-list_paginate {
		padding: 10px;
	}
	
	table#member-list {
		margin-top: 10px;
		display: inline-block;

		@include respond-to(small) {
			font-size: 0.8rem;
		}

		tr.odd {
			background-color: #FAF8F5;

			th.sorting_1 {
				background-color: #E9E3DA;
			}
		}

		tr.even {
			background-color: #fff;

			th.sorting_1 {
				background-color: #FAF8F5;
			}
		}

		th {
			border-top: none;
			text-align: left;
		}

	}


		thead {
			background-color: #5C594F;


			th {
				color: #FAF8F5;
			}
		}

		select[name="member-list_length"] {
			border: 1px solid #ddd;
			outline: none;
		}

		#member-list_filter {
			background-color: #E6DFD5;
			padding: 5px 10px;
			border-radius: 15px;

			input {
				outline: none;
			}
		}
	
}