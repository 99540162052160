/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */

@function break-bigger($width) {
	@return "screen and (min-width: #{$width})";
}

@function break-smaller($width) {
	@return "screen and (max-width: #{$width})";
}

$responses: (
	wider-than-page: break-bigger($site-width),
	narrower-than-page: break-smaller($site-width),
	wider-than-nav: break-bigger($mobile-nav-width),
	narrower-than-nav: break-smaller($mobile-nav-width),
	medium: break-smaller(970px),
	small: break-smaller(640px)
);

@mixin respond-to($name) {
	@if map-has-key($responses, $name) {
		@media #{map-get($responses, $name)} {
			@content;
		}
	} @else {
		@error "There is no response named `#{$name}`, make sure it's defined in the `$responses` map.";
	}
}