%wysiwyg, .wysiwyg {
	a {
		color: $color-orange;
		transition: all 0.2s ease-in-out;
		padding: 0px 2px;

		&:hover, &:focus {
			color: #fff;
			background-color: $color-dark-orange;
		}
	}

	p {
		margin: 1em 0;
	}

	h1 {
		// font-size^4
		font-size: 2.5rem;
		color: $color-blue;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
	}

	h2 {
		// font-size^3
		font-size: 1.8rem;
		color: $color-blue;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;

		@include respond-to(small) {
			font-size: 2rem;
		}
	}

	h3 {
		// font-size^2
		font-size: 1.6rem;
		color: $color-blue;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
	}

	h4 {
		// font-size
		font-size: 1.6rem;
		color: $color-blue;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	h5 {
		font-size: 1rem;
		color: $color-blue;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
	}

	h6 {
		font-size: 0.8rem;
		color: $color-blue;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
	}

	ul {

		margin-left: 0px;
		margin-bottom: 15px;
		display: inline-block;
		width: 100%;

		li {
			list-style: none;
			font-size: 1em;
			font-weight: lighter;
			margin-bottom: 15px;
			padding-left: 30px;
			text-indent: -30px;

			strong {
				font-weight: normal;
			}

			&:before {
				content: '\f058';
				font-family: fontawesome;
				color: $color-orange;
				margin-right: 14px;
				font-size: 1em;
			}

			ul {
				margin-bottom: 0px;
				margin-top: 15px;

				li {
					font-size: 1em;
					margin-bottom: 10px;

					&:before {
						content: '\f0da';
					}
				}
			}
		}
	}

	ol {
		margin-left: 0;
		counter-reset: item;
   		list-style-type: none;
   		margin-top: 15px;
   		display: inline-block;

   		li {

   			margin-bottom: 15px;
   			font-weight: lighter;
   			font-size: 1em;
   			padding-left: 25px;
			text-indent: -25px;

   			&:before {
	   			content: counter(item) '.';
	   			counter-increment: item;
	   			margin-right: 10px;
	   			color: $color-orange;

   			}
   		}

	}

	blockquote {
		display: flex;

		&::before {
			display: inline-block;
			font-family: FontAwesome;
			font-size: 2rem;
			content: "\f10d";
			color: rgb(200, 200, 200);
			padding-right: 0.5em;
		}

		cite {
			font-weight: bold;

			&::before {
				content: "\2014";
				padding-right: 0.5em;
			}
		}
	}

	input {
		outline: none;
	}
}

.page-template-default main.page.wysiwyg {
	padding-bottom:80px;
}