/**
 * Styles the slide-out mobile drawer
 */

dialog.mobile-menu {
	position: fixed;
	top: 0;
	margin-top: 50px;
	z-index: 105;
	width: 70vw;
	min-width: 280px;
	max-width: 400px;
	height: 100vh;
	background-color: white;
	overflow: scroll;
	padding-bottom: 80px;

	transition: left 0.5s ease-in-out, visibility 0s linear 0.5s;
	left: -100%;

	&[open] {
		transition-delay: 0s;
		top: 0;
		left: 0;
	}

	> header {
		padding: 8px;
		margin-bottom: 16px;

		border-bottom: 1px solid black;
	}

	nav {
		padding: 0px;
		padding-top: 30px;
		font-size: 24px;

		ul {

			margin: 0;
			padding: 0; 

			li {
				display: block;
				padding: 10px 20px;
				margin-bottom: 0px;
				border-bottom: 1px solid $color-light-grey;
				color: $color-blue; 

				i {
					display: none;
				}
			}
		}
	}
}