/* Touch Nav Detection (2.0.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
@mixin with-touch-nav() {
	@include respond-to("narrower-than-nav") {
		@content;
	}

	@if & {
		@at-root html.touch & {
			@content;
		}
	} @else {
		@at-root html.touch {
			@content;
		}
	}
}

@mixin without-touch-nav {
	@if & {
		@at-root html.no-touch & {
			@content;

			@include respond-to("wider-than-nav") {
				@content;
			}
		}
	} @else {
		@at-root html.no-touch {
			@content;

			@include respond-to("wider-than-nav") {
				@content;
			}
		}
	}
}