/**
 * Base Styles
 * The styles that go here should be site-global and not specific to any individual components or sections.
 */

body {
	font-family: 'Open Sans', sans-serif;
	line-height: 1.3;
	font-size: $base-font-size;
	overflow-x: hidden;
}

img {
	max-width: 100%;
	height: auto;
}

%wysiwyg, .wysiwyg {
	#{$buttons} {
		display: inline-block;
		cursor: pointer;
		padding: 15px 40px;
		border: 1px solid $color-orange;
		border-radius: 25px;
		margin: 4px;
		background-color: #fff;
		color: $color-blue;
		transition: background-color 0.2s ease-in-out;
		text-transform: uppercase;

		&:hover, &:focus {
			background-color: $color-orange;
			color: #fff;
		}
	}
}

#{$text-inputs} {
	border: 1px solid $color-interactive;
	padding: 8px;
	margin: 4px;
	max-width: 100%;
}