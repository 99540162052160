main.index, main.news-page, main.single {

	.news-row {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 60px;

		.news-image {
			flex-basis: 25%;

			@media screen and (max-width: 900px) {
				flex-basis: 100%;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}

		.news-content {
			flex-basis: 75%;
			padding-left: 20px;
			
			@media screen and (max-width: 900px) {
				flex-basis: 100%;
			}

			h1 {
				font-size: 2rem;
				line-height: 3rem;

				 a {
					border: none;
					color: $color-blue;
					font-size: 2.5rem;
					line-height: 1rem;

					&:hover {
						background-color: transparent;
					}
				}
			}

			.excerpt {
				padding: 0;
			}
		}
	}

	.post-meta { 

		font-size: 0.8em;

		span.post-date {
			&:before {
				content: '\f073';
				font-family: fontawesome;
				margin-right: 5px;
				color: $color-orange;
				font-size: 1.2em;
			}
		}

		span.post-author {
			margin-left: 10px;

			&:before {
				content: '\f007';
				font-family: fontawesome;
				margin-right: 5px;
				color: $color-orange;
				font-size: 1.2em;
			}
		}

		span.post-comments {
			margin-left: 10px;

			&:before {
				content: '\f075';
				font-family: fontawesome;
				margin-right: 5px;
				color: $color-orange;
				font-size: 1.2em;
			}
		}
	} 
}

section.post-archive {

	background-color: #FAF8F5;
	padding-bottom: 80px;

	@include full-width {
		background-color: #FAF8F5;
	}

	display: flex;
	flex-wrap: wrap;

	.archive-post {
		flex-basis: 25%;
		min-width: 200px;
		padding: 30px 20px;
		flex-grow: 1;
		max-width: 300px;

		a {
			&:hover {
				background-color: transparent;
			}
		}

		.archive-image {
			height: 175px;
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
		}

		h2 {
			color: $color-black; 
			font-size: 1.6em;
			margin-top: 10px;
		}

		span.post-date {
			color: $color-black; 
			font-size: 0.8em;

			&:before {
				content: '\f073';
				font-family: fontawesome;
				margin-right: 5px;
				color: $color-orange;
				font-size: 1.2em;
			}
		}

		p {
			color: $color-black; 
		}

		a.read-more {
			width: 100%;
			margin-top: 10px;
			float: left;
			font-weight: bold;

			&:hover {
				color: $color-dark-orange;
			}
		}
	}
} 

.banner.single {
	margin-bottom: 60px;
}

main.single {

	padding-bottom: 80px;

	@include with-touch-nav {
		padding-top: 70px;
	}

}