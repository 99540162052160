/**
 * Styles the mobile/touch header on all pages
 */

body > header.mobile {

	display: none;
	padding:5px 10px 5px 0px;
	width: 100%;
	height: 50px;
	background-color: $color-blue ;
	color: #D7E9E7;
	position: fixed;
	z-index: 140;

	@include with-touch-nav {
		display: flex;
		justify-content: space-between;
	}

	// The menu toggle button
	.toggle-mobile-menu {
		display: inline-block;
		font-weight: 100;
		font-size: 1em;
		cursor: pointer;

		&::before {
			font-family: FontAwesome;
			font-size: 2em;
			width: 100%;
			margin-right: 0.5em;
			vertical-align: top;
			content: "\f0c9";
		}
	}

	.logo {
		display: inline-block;
		vertical-align: middle;

		img.small-logo {
			width: 50px;
		}

		a.small-logo-link {
			background-color: $color-blue;
			padding: 10px;
			display: inline-block;
			width: 70px;
			height: 70px;
			border-radius: 35px;
			margin-top: -10px;

		}

		a.text {
			vertical-align: top;
			padding-top: 12px;
			display: inline-block;
		}
	}
}