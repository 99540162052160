@charset "UTF-8";
/**
 * Stylesheet entrypoint
 */
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
/**
 * Gecko Designs Reset
 * Based on Eric Meyer's CSS Reset
 */
html, body, div, span, applet, object, iframe,
button, input, select, textarea,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, main, dialog,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  background: none;
  vertical-align: baseline;
  color: inherit;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section,
main, dialog {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  text-decoration: none;
}

/**
 * Site-wide constants
 */
/**
 * Site color definitions
 * Includes both name-specific and semantic names
 */
/* Breakpoint Utilities (1.0.0)
 * Provides utilities for imlementing custom, flexible breakpoints.
 */
/* Full-width mixin (1.0.0)
 * Creates a full-width section
 * Side-effect: element must be position relative
 */
/* Touch Nav Detection (2.0.0)
 * Determines whether we should be using touch navigation or not.
 * Pass in 'false' to the argument if the mixin is being used in the root instead of in a block.
 */
body > .backdrop {
  content: "";
  display: block;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  cursor: pointer;
  transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s;
  opacity: 0.7;
  visibility: visible;
}
body > .backdrop:not([hidden]) {
  transition-delay: 0s;
}
body > .backdrop[hidden] {
  display: block;
  opacity: 0;
  visibility: hidden;
}

dialog {
  display: block;
  position: fixed;
  z-index: 50;
  transition: top 0.5s ease-in-out, visibility 0s linear 0.5s;
  visibility: hidden;
  left: 0;
  right: 0;
  top: -100%;
}
dialog[open] {
  transition-delay: 0s;
  visibility: visible;
  top: 100px;
}
dialog .close::before {
  content: "\f00d";
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.5);
}

/**
 * Base Styles
 * The styles that go here should be site-global and not specific to any individual components or sections.
 */
body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  font-size: 16px;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

.wysiwyg button, .wysiwyg a.button, .wysiwyg input[type=button], .wysiwyg input[type=submit] {
  display: inline-block;
  cursor: pointer;
  padding: 15px 40px;
  border: 1px solid #EF9501;
  border-radius: 25px;
  margin: 4px;
  background-color: #fff;
  color: #1A519A;
  transition: background-color 0.2s ease-in-out;
  text-transform: uppercase;
}
.wysiwyg button:hover, .wysiwyg button:focus, .wysiwyg a.button:hover, .wysiwyg a.button:focus, .wysiwyg input[type=button]:hover, .wysiwyg input[type=button]:focus, .wysiwyg input[type=submit]:hover, .wysiwyg input[type=submit]:focus {
  background-color: #EF9501;
  color: #fff;
}

textarea, input[type=text], input[type=email], input[type=password], input[type=tel] {
  border: 1px solid #1A519A;
  padding: 8px;
  margin: 4px;
  max-width: 100%;
}

.wysiwyg a {
  color: #EF9501;
  transition: all 0.2s ease-in-out;
  padding: 0px 2px;
}
.wysiwyg a:hover, .wysiwyg a:focus {
  color: #fff;
  background-color: #C07B2B;
}
.wysiwyg p {
  margin: 1em 0;
}
.wysiwyg h1 {
  font-size: 2.5rem;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.wysiwyg h2 {
  font-size: 1.8rem;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
@media screen and (max-width: 640px) {
  .wysiwyg h2 {
    font-size: 2rem;
  }
}
.wysiwyg h3 {
  font-size: 1.6rem;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.wysiwyg h4 {
  font-size: 1.6rem;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.wysiwyg h5 {
  font-size: 1rem;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.wysiwyg h6 {
  font-size: 0.8rem;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}
.wysiwyg ul {
  margin-left: 0px;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}
.wysiwyg ul li {
  list-style: none;
  font-size: 1em;
  font-weight: lighter;
  margin-bottom: 15px;
  padding-left: 30px;
  text-indent: -30px;
}
.wysiwyg ul li strong {
  font-weight: normal;
}
.wysiwyg ul li:before {
  content: '\f058';
  font-family: fontawesome;
  color: #EF9501;
  margin-right: 14px;
  font-size: 1em;
}
.wysiwyg ul li ul {
  margin-bottom: 0px;
  margin-top: 15px;
}
.wysiwyg ul li ul li {
  font-size: 1em;
  margin-bottom: 10px;
}
.wysiwyg ul li ul li:before {
  content: '\f0da';
}
.wysiwyg ol {
  margin-left: 0;
  counter-reset: item;
  list-style-type: none;
  margin-top: 15px;
  display: inline-block;
}
.wysiwyg ol li {
  margin-bottom: 15px;
  font-weight: lighter;
  font-size: 1em;
  padding-left: 25px;
  text-indent: -25px;
}
.wysiwyg ol li:before {
  content: counter(item) ".";
  counter-increment: item;
  margin-right: 10px;
  color: #EF9501;
}
.wysiwyg blockquote {
  display: flex;
}
.wysiwyg blockquote::before {
  display: inline-block;
  font-family: FontAwesome;
  font-size: 2rem;
  content: "\f10d";
  color: #c8c8c8;
  padding-right: 0.5em;
}
.wysiwyg blockquote cite {
  font-weight: bold;
}
.wysiwyg blockquote cite::before {
  content: "\2014";
  padding-right: 0.5em;
}
.wysiwyg input {
  outline: none;
}

.page-template-default main.page.wysiwyg {
  padding-bottom: 80px;
}

/**
 * Content styling
 * Styles the body of all pages
 */
.banner {
  position: relative;
  margin-bottom: 80px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (max-width: 640px) {
  .banner {
    padding-top: 50px;
  }
}
.banner h1 {
  position: absolute;
  bottom: -30px;
  left: 0;
  display: inline-block;
  margin: 0px;
  padding: 0px 40px;
  font-size: 35px;
  font-weight: bold;
  line-height: 70px;
  color: #5e5951;
  text-transform: uppercase;
  background-color: #E6DFD5;
}
@media screen and (max-width: 640px) {
  .banner h1 {
    font-size: 30px;
    width: 100%;
    padding: 20px;
    text-align: center;
    line-height: 1.2em;
    bottom: -20px;
  }
}
.banner .row {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  position: relative;
  height: 300px;
}

main {
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
}
main .content {
  padding: 0px 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
}
main .content .row {
  display: flex;
  flex-wrap: wrap;
}
main p {
  font-weight: lighter;
  line-height: 1.6em;
}

.column-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 60px;
}
.column-wrapper .page-left-col {
  flex-basis: 60%;
  flex-grow: 1;
  padding: 0px 20px;
  margin: 0;
}
@media screen and (max-width: 640px) {
  .column-wrapper .page-left-col {
    flex-basis: 100%;
    width: 100%;
  }
}
.column-wrapper .page-left-col img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.column-wrapper .page-right-col {
  flex-basis: 40%;
  padding: 0px 20px;
  flex-grow: 1;
}
@media screen and (max-width: 640px) {
  .column-wrapper .page-right-col {
    flex-basis: 100%;
    width: 100%;
  }
}

body.single .wysiwyg h1 {
  font-size: 2.5rem;
}

/**
 * Styles the footer on all pages
 */
.testimonials {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  position: relative;
  z-index: 0;
  padding-top: 80px;
  padding-bottom: 100px;
}
.testimonials::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(239, 149, 0, 0.7);
  background-image: url("../images/orange-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media screen and (min-width: 1140px) {
  .testimonials::before {
    left: calc(-50vw + 1140px / 2);
  }
}
.testimonials blockquote {
  padding-left: 60px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .testimonials blockquote {
    padding-left: 50px;
  }
}
.testimonials blockquote:before {
  content: "";
  font-family: fontawesome;
  font-size: 2em;
  color: #1C5BA4;
  position: absolute;
  left: 0;
  padding-left: 10px;
}
.testimonials blockquote p {
  font-style: italic;
  font-size: 30px;
  line-height: 46px;
  color: #fff;
}
@media screen and (max-width: 640px) {
  .testimonials blockquote p {
    font-size: 18px;
    line-height: 1.6em;
  }
}
.testimonials blockquote cite {
  font-style: normal;
  font-size: 16px;
  font-weight: bold;
  line-height: 46px;
  color: #1A519A;
}
.testimonials blockquote cite:before {
  content: "- ";
}
.testimonials .rotary-icon-yellow {
  width: 100px;
  background-color: #B37601;
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}
.testimonials .rotary-icon-yellow img {
  width: 80px;
  height: auto;
}

body > footer {
  text-align: center;
  background-color: #1A519A;
  color: #fff;
  position: relative;
  position: relative;
  z-index: 0;
}
body > footer::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #1A519A;
}
@media screen and (min-width: 1140px) {
  body > footer::before {
    left: calc(-50vw + 1140px / 2);
  }
}
body > footer nav ul > li {
  display: inline-block;
}
body > footer nav ul > li > a {
  display: block;
  padding: 8px 20px;
}
body > footer .colophon {
  padding: 20px 10px;
  text-align: center;
}
body > footer .colophon h2 {
  color: #D7E9E7;
  font-weight: bold;
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 1.4em;
  margin-top: 40px;
  margin-bottom: 20px;
}
body > footer .colophon p {
  color: #D7E9E7;
  margin-bottom: 20px;
  font-weight: lighter;
}
body > footer .colophon p.copyright {
  color: #fff;
}
body > footer .rotary-icon {
  width: 100px;
  background-color: #1A519A;
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}
body > footer .rotary-icon img {
  width: 80px;
  height: auto;
}

/**
 * Styles the desktop header on all pages
 */
body > header.desktop {
  background-color: #fff;
}
body > header.desktop .top-menu,
body > header.desktop .main-header {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
}
@media screen and (max-width: 640px) {
  body > header.desktop {
    display: none;
  }
}
html.touch body > header.desktop {
  display: none;
}
body > header.desktop .top-menu {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  font-weight: lighter;
  position: relative;
  z-index: 0;
  background-color: #1A519A;
  position: relative;
}
body > header.desktop .top-menu::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #1A519A;
}
@media screen and (min-width: 1140px) {
  body > header.desktop .top-menu::before {
    left: calc(-50vw + 1140px / 2);
  }
}
body > header.desktop .top-menu a {
  color: #D7E9E7;
}
body > header.desktop .top-menu a:hover {
  color: #fff;
}
body > header.desktop .top-menu .menu li {
  display: inline-block;
  margin-left: 20px;
  font-size: .8em;
}
@media screen and (max-width: 970px) {
  body > header.desktop .top-menu .menu li {
    margin-left: 10px;
  }
}
body > header.desktop .top-menu .menu li i {
  color: #BAB5AD;
  font-size: 1.1em;
}
body > header.desktop .top-menu .menu li.give a {
  color: #EF9501;
  text-transform: uppercase;
  font-weight: bold;
}
body > header.desktop .top-menu .menu li.give a i {
  color: #EF9501;
}
body > header.desktop .top-menu .menu li.give a:hover i {
  font-size: 1.3em;
  margin-left: -2.5px;
}
body > header.desktop .top-menu .menu .no-text span.fontawesome-text {
  display: none;
}
body > header.desktop .top-menu .menu .no-text i {
  font-size: 1.3em;
}
body > header.desktop .top-menu .menu .no-text i:hover {
  color: #fff;
}
body > header.desktop .main-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0px 10px;
}
@media screen and (max-width: 970px) {
  body > header.desktop .main-header {
    flex-wrap: wrap;
  }
}
body > header.desktop .main-header a.logo {
  height: auto;
  display: inline-block;
}
@media screen and (max-width: 970px) {
  body > header.desktop .main-header a.logo {
    flex-basis: 100%;
    text-align: center;
  }
}
body > header.desktop .main-header a.logo img {
  max-width: 200px;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  flex-grow: 0;
}
@media screen and (max-width: 970px) {
  body > header.desktop .main-header a.logo img {
    max-width: 200px;
    height: auto;
  }
}
body > header.desktop nav {
  background-color: #fff;
  color: white;
  margin: 0;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  flex-basis: 100%;
  padding-left: 30px;
}
@media screen and (max-width: 970px) {
  body > header.desktop nav {
    padding-left: 0;
  }
}
body > header.desktop nav ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
body > header.desktop nav ul > li {
  display: inline-block;
  text-align: center;
}
body > header.desktop nav ul > li:last-child a {
  padding-right: 0px;
}
body > header.desktop nav ul > li.current-menu-item > a, body > header.desktop nav ul > li.current_page_item > a {
  border-bottom: 5px solid #5D5951;
  padding-bottom: 35px;
  color: #5D5951;
}
body > header.desktop nav ul > li > a {
  display: block;
  padding: 35px 15px 40px 15px;
  color: #1A519A;
}
@media screen and (max-width: 970px) {
  body > header.desktop nav ul > li > a {
    padding: 35px 5px 40px 5px;
  }
}
body > header.desktop nav ul > li > a:hover {
  border-bottom: 5px solid #5D5951;
  padding-bottom: 35px;
  color: #5D5951;
}
body > header.desktop nav ul > li > .sub-menu {
  display: none;
}

/**
 * Styles the mobile/touch header on all pages
 */
body > header.mobile {
  display: none;
  padding: 5px 10px 5px 0px;
  width: 100%;
  height: 50px;
  background-color: #1A519A;
  color: #D7E9E7;
  position: fixed;
  z-index: 140;
}
@media screen and (max-width: 640px) {
  body > header.mobile {
    display: flex;
    justify-content: space-between;
  }
}
html.touch body > header.mobile {
  display: flex;
  justify-content: space-between;
}
body > header.mobile .toggle-mobile-menu {
  display: inline-block;
  font-weight: 100;
  font-size: 1em;
  cursor: pointer;
}
body > header.mobile .toggle-mobile-menu::before {
  font-family: FontAwesome;
  font-size: 2em;
  width: 100%;
  margin-right: 0.5em;
  vertical-align: top;
  content: "\f0c9";
}
body > header.mobile .logo {
  display: inline-block;
  vertical-align: middle;
}
body > header.mobile .logo img.small-logo {
  width: 50px;
}
body > header.mobile .logo a.small-logo-link {
  background-color: #1A519A;
  padding: 10px;
  display: inline-block;
  width: 70px;
  height: 70px;
  border-radius: 35px;
  margin-top: -10px;
}
body > header.mobile .logo a.text {
  vertical-align: top;
  padding-top: 12px;
  display: inline-block;
}

/**
 * Styles the slide-out mobile drawer
 */
dialog.mobile-menu {
  position: fixed;
  top: 0;
  margin-top: 50px;
  z-index: 105;
  width: 70vw;
  min-width: 280px;
  max-width: 400px;
  height: 100vh;
  background-color: white;
  overflow: scroll;
  padding-bottom: 80px;
  transition: left 0.5s ease-in-out, visibility 0s linear 0.5s;
  left: -100%;
}
dialog.mobile-menu[open] {
  transition-delay: 0s;
  top: 0;
  left: 0;
}
dialog.mobile-menu > header {
  padding: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid black;
}
dialog.mobile-menu nav {
  padding: 0px;
  padding-top: 30px;
  font-size: 24px;
}
dialog.mobile-menu nav ul {
  margin: 0;
  padding: 0;
}
dialog.mobile-menu nav ul li {
  display: block;
  padding: 10px 20px;
  margin-bottom: 0px;
  border-bottom: 1px solid #BAB5AD;
  color: #1A519A;
}
dialog.mobile-menu nav ul li i {
  display: none;
}

main.about-us .about-list {
  margin-top: 40px;
}
main.about-us .about-list ul {
  margin-left: 0px;
  margin-top: 20px;
}
main.about-us .about-list ul li {
  list-style: none;
  color: #5e5951;
  font-weight: bold;
  margin-bottom: 15px;
  display: block;
  text-indent: -22px;
  margin-left: 22px;
}
main.about-us .about-list ul li:before {
  content: '\f058';
  font-family: fontawesome;
  color: #EF9501;
  margin-right: 10px;
}
main.about-us .tab-member-list {
  font-size: 11px;
}
main.about-us #page-wrapper .page-content .row img {
  display: none;
}

#member-list_wrapper {
  background-color: #fff;
}
#member-list_wrapper #member-list_length {
  padding-left: 10px;
}
#member-list_wrapper #member-list_filter {
  margin-right: 10px;
}
#member-list_wrapper #member-list_info {
  margin-left: 10px;
}
#member-list_wrapper #member-list_paginate {
  padding: 10px;
}
#member-list_wrapper table#member-list {
  margin-top: 10px;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  #member-list_wrapper table#member-list {
    font-size: 0.8rem;
  }
}
#member-list_wrapper table#member-list tr.odd {
  background-color: #FAF8F5;
}
#member-list_wrapper table#member-list tr.odd th.sorting_1 {
  background-color: #E9E3DA;
}
#member-list_wrapper table#member-list tr.even {
  background-color: #fff;
}
#member-list_wrapper table#member-list tr.even th.sorting_1 {
  background-color: #FAF8F5;
}
#member-list_wrapper table#member-list th {
  border-top: none;
  text-align: left;
}
#member-list_wrapper thead {
  background-color: #5C594F;
}
#member-list_wrapper thead th {
  color: #FAF8F5;
}
#member-list_wrapper select[name="member-list_length"] {
  border: 1px solid #ddd;
  outline: none;
}
#member-list_wrapper #member-list_filter {
  background-color: #E6DFD5;
  padding: 5px 10px;
  border-radius: 15px;
}
#member-list_wrapper #member-list_filter input {
  outline: none;
}

.contact-page .contact-left {
  width: 50%;
  float: left;
  padding-left: 20px;
}
@media screen and (max-width: 640px) {
  .contact-page .contact-left {
    width: 100%;
    padding: 10px 15px;
  }
}
.contact-page .contact-left a.telephone {
  font-size: 2em;
  font-weight: bold;
  color: #1A519A;
}
.contact-page .contact-left a.telephone:hover {
  background-color: transparent;
}
.contact-page .contact-left a.telephone:before {
  content: '\f098';
  font-family: fontawesome;
  margin-right: 10px;
  color: #EF9501;
}
.contact-page .contact-right {
  width: 50%;
  float: left;
  display: inline-block;
}
@media screen and (max-width: 640px) {
  .contact-page .contact-right {
    width: 100%;
    padding: 10px 15px;
  }
}
.contact-page .contact-right form.contact-form {
  position: relative;
  z-index: 20;
}
.contact-page .contact-right form.contact-form ul {
  display: flex;
  flex-wrap: wrap;
}
.contact-page .contact-right form.contact-form ul li {
  padding-left: 0;
  padding-right: 20px;
  text-indent: 0;
}
.contact-page .contact-right form.contact-form ul li label {
  margin-bottom: 0px;
  font-weight: normal;
}
.contact-page .contact-right form.contact-form ul li input {
  width: 100%;
  margin: 0px;
  outline: none;
}
.contact-page .contact-right form.contact-form ul li textarea {
  width: 100%;
  margin: 0;
  height: 200px;
  padding: 10px 10px 80px 10px;
  outline: none;
  background-color: #fff;
}
.contact-page .contact-right form.contact-form ul li#field_1_1 {
  width: 50%;
}
@media screen and (max-width: 640px) {
  .contact-page .contact-right form.contact-form ul li#field_1_1 {
    width: 100%;
  }
}
.contact-page .contact-right form.contact-form ul li#field_1_2 {
  width: 50%;
}
@media screen and (max-width: 640px) {
  .contact-page .contact-right form.contact-form ul li#field_1_2 {
    width: 100%;
  }
}
.contact-page .contact-right form.contact-form ul li#field_1_3 {
  width: 100%;
}
.contact-page .contact-right form.contact-form .gform_footer {
  margin: 0;
  padding: 0;
  position: relative;
}
.contact-page .contact-right form.contact-form .gform_footer input.button {
  position: absolute;
  top: -85px;
  right: 30px;
}
@media screen and (max-width: 640px) {
  .contact-page .contact-right form.contact-form .gform_footer input.button {
    position: relative;
    top: 0;
    left: 0;
  }
}

.maps {
  display: inline-block;
  width: 100%;
  margin-top: -140px;
}
@media screen and (max-width: 640px) {
  .maps {
    margin-top: 20px;
  }
}

.maps iframe {
  pointer-events: none;
}

/**
 * Styles the front page
 */
.slider {
  background-color: #fff;
}
@media screen and (max-width: 640px) {
  .slider {
    padding-top: 50px;
  }
}
.slider .soliloquy-wrapper {
  max-height: 500px;
  overflow: hidden;
}
.slider pre {
  display: none;
}
.slider img.soliloquy-image {
  width: 100%;
}
.slider .soliloquy-container {
  margin-bottom: 0px !important;
}
.slider .soliloquy-container .soliloquy-caption {
  top: 50%;
  left: 10%;
}
@media screen and (min-width: 1300px) {
  .slider .soliloquy-container .soliloquy-caption {
    top: 30%;
  }
}
.slider .soliloquy-container .soliloquy-caption .soliloquy-caption-inside {
  background: transparent;
  height: 100%;
  text-align: left;
}
.slider .soliloquy-container .soliloquy-caption .soliloquy-caption-inside h1 {
  font-size: 3em;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
}
@media screen and (max-width: 900px) {
  .slider .soliloquy-container .soliloquy-caption .soliloquy-caption-inside h1 {
    font-size: 2rem;
  }
}
@media screen and (max-width: 640px) {
  .slider .soliloquy-container .soliloquy-caption .soliloquy-caption-inside h1 {
    font-size: 1.5rem;
  }
}

main.page.front.wysiwyg {
  position: relative;
  padding-top: 50px;
  padding-bottom: 0px;
}
main.page.front.wysiwyg .rotary-icon-white {
  width: 100px;
  background-color: #fff;
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}
main.page.front.wysiwyg .rotary-icon-white img {
  width: 80px;
  height: auto;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .rotary-icon-white {
    display: none;
  }
}
main.page.front.wysiwyg .content {
  display: flex;
  padding-top: 60px;
  padding-bottom: 100px;
}
main.page.front.wysiwyg .content h1 {
  margin: 0px 0px 45px 0px;
  font-size: 46px;
  text-transform: none;
}
main.page.front.wysiwyg .content h2 {
  margin: 15px 0px 12px 0px;
  color: #1A519A;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  font-size: 30px;
}
main.page.front.wysiwyg .content span.calendar, main.page.front.wysiwyg .content span.location {
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #4c4c4c;
}
main.page.front.wysiwyg .content span.calendar:before, main.page.front.wysiwyg .content span.location:before {
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  font-family: fontawesome;
  color: #1a519a;
  text-align: center;
}
main.page.front.wysiwyg .content span.calendar:before {
  content: "\f133";
}
main.page.front.wysiwyg .content span.location:before {
  content: "\f041";
}
main.page.front.wysiwyg .content p {
  margin-bottom: 25px;
  font-size: 16px;
  line-height: 26px;
  color: #4c4c4c;
}
main.page.front.wysiwyg .content.grey {
  position: relative;
  z-index: 0;
}
main.page.front.wysiwyg .content.grey::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #F5F5F5;
}
@media screen and (min-width: 1140px) {
  main.page.front.wysiwyg .content.grey::before {
    left: calc(-50vw + 1140px / 2);
  }
}
main.page.front.wysiwyg .content.brown {
  position: relative;
  z-index: 0;
}
main.page.front.wysiwyg .content.brown::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #E6DFD5;
}
@media screen and (min-width: 1140px) {
  main.page.front.wysiwyg .content.brown::before {
    left: calc(-50vw + 1140px / 2);
  }
}
main.page.front.wysiwyg .content.brown ul li {
  display: inline-block;
  margin-right: 20px;
  color: #595959;
  font-size: 18px;
  line-height: 36px;
}
main.page.front.wysiwyg .content section.week, main.page.front.wysiwyg .content section.news, main.page.front.wysiwyg .content section.largest, main.page.front.wysiwyg .content section.events {
  flex-basis: 50%;
}
main.page.front.wysiwyg .rotary-icon-grey {
  width: 100px;
  background-color: #F5F5F5;
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}
main.page.front.wysiwyg .rotary-icon-grey img {
  width: 80px;
  height: auto;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .rotary-icon-grey {
    display: none;
  }
}
main.page.front.wysiwyg .rotary-icon-brown {
  width: 100px;
  background-color: #E6DFD5;
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}
main.page.front.wysiwyg .rotary-icon-brown img {
  width: 80px;
  height: auto;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .rotary-icon-brown {
    display: none;
  }
}
main.page.front.wysiwyg .column-2 {
  flex-basis: 18.75rem;
  flex-grow: 1;
  padding: 0px 20px;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .column-2 {
    padding-bottom: 60px;
  }
}
main.page.front.wysiwyg .row {
  display: flex;
  flex-flow: wrap row;
}
main.page.front.wysiwyg .event-preview h3 {
  margin-bottom: 20px;
}
main.page.front.wysiwyg .event-preview .event-single {
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 20px;
  background-color: transparent;
}
main.page.front.wysiwyg .event-preview .event-single:active {
  background-color: transparent;
  color: #EF9501;
}
main.page.front.wysiwyg .event-preview .event-single:focus {
  background-color: transparent;
  color: #EF9501;
}
main.page.front.wysiwyg .event-preview .event-single a {
  display: block;
  background-color: transparent;
}
main.page.front.wysiwyg .event-preview .event-single a:hover {
  background-color: transparent;
  color: #EF9501;
}
main.page.front.wysiwyg .event-preview .event-single .event-date {
  width: 50px;
  height: 55px;
  padding-top: 2px;
  margin-right: 15px;
  background-color: #DAEAE7;
  color: #5D5952;
  text-align: center;
  text-transform: uppercase;
}
main.page.front.wysiwyg .event-preview .event-single .event-date .day {
  width: 100%;
  font-weight: bold;
  font-size: 1.8em;
  display: inline-block;
  line-height: 1em;
}
main.page.front.wysiwyg .event-preview .event-single .event-date .month {
  width: 100%;
  display: inline-block;
  line-height: 1em;
}
main.page.front.wysiwyg .event-preview .event-single .event-info small a {
  display: inline-block;
}
main.page.front.wysiwyg .event-preview .event-single h2 {
  font-size: 1.2em;
}
main.page.front.wysiwyg .blog-posts a.read-more, main.page.front.wysiwyg .this-week a.read-more {
  display: none;
}
main.page.front.wysiwyg .blog-posts .post-preview, main.page.front.wysiwyg .this-week .post-preview {
  margin-bottom: 40px;
}
main.page.front.wysiwyg .blog-posts .post-preview img, main.page.front.wysiwyg .this-week .post-preview img {
  width: 33%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .blog-posts .post-preview img, main.page.front.wysiwyg .this-week .post-preview img {
    width: 100%;
  }
}
main.page.front.wysiwyg .blog-posts .post-preview a.read-article, main.page.front.wysiwyg .this-week .post-preview a.read-article {
  width: 100%;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed', sans-serif;
  font-weight: bold;
  color: #EF9501;
}
main.page.front.wysiwyg .blog-posts .post-preview a.read-article:hover, main.page.front.wysiwyg .this-week .post-preview a.read-article:hover {
  color: #fff;
}
main.page.front.wysiwyg .blog-posts .post-preview a.read-article:after, main.page.front.wysiwyg .this-week .post-preview a.read-article:after {
  content: '\f178';
  font-family: fontawesome;
  color: #BAB5AD;
  margin-left: 10px;
}
main.page.front.wysiwyg .blog-posts .post-preview:nth-child(3), main.page.front.wysiwyg .blog-posts .post-preview:nth-child(4), main.page.front.wysiwyg .this-week .post-preview:nth-child(3), main.page.front.wysiwyg .this-week .post-preview:nth-child(4) {
  width: 50%;
  float: left;
  padding-right: 20px;
  min-width: 200px;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .blog-posts .post-preview:nth-child(3), main.page.front.wysiwyg .blog-posts .post-preview:nth-child(4), main.page.front.wysiwyg .this-week .post-preview:nth-child(3), main.page.front.wysiwyg .this-week .post-preview:nth-child(4) {
    width: 100%;
  }
}
main.page.front.wysiwyg .blog-posts .post-preview:nth-child(3) img, main.page.front.wysiwyg .blog-posts .post-preview:nth-child(4) img, main.page.front.wysiwyg .this-week .post-preview:nth-child(3) img, main.page.front.wysiwyg .this-week .post-preview:nth-child(4) img {
  display: none;
}
main.page.front.wysiwyg .blog-posts .post-preview:nth-child(3) p, main.page.front.wysiwyg .blog-posts .post-preview:nth-child(4) p, main.page.front.wysiwyg .this-week .post-preview:nth-child(3) p, main.page.front.wysiwyg .this-week .post-preview:nth-child(4) p {
  display: none;
}
main.page.front.wysiwyg .blog-posts .post-preview:nth-child(3) h2, main.page.front.wysiwyg .blog-posts .post-preview:nth-child(4) h2, main.page.front.wysiwyg .this-week .post-preview:nth-child(3) h2, main.page.front.wysiwyg .this-week .post-preview:nth-child(4) h2 {
  font-size: 1.5em;
}
main.page.front.wysiwyg .home-grey {
  background-color: #F5F5F5;
  padding: 50px 0px;
  margin-top: 50px;
  position: relative;
  z-index: 0;
}
main.page.front.wysiwyg .home-grey::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #F5F5F5;
}
@media screen and (min-width: 1140px) {
  main.page.front.wysiwyg .home-grey::before {
    left: calc(-50vw + 1140px / 2);
  }
}
main.page.front.wysiwyg .home-grey .home-section img {
  width: 50%;
  height: auto;
  margin: 20px 0px;
}
main.page.front.wysiwyg .home-grey .home-calendar li.widget {
  list-style: none;
}
main.page.front.wysiwyg .home-grey .home-calendar table {
  width: 100%;
  margin-top: 20px;
}
main.page.front.wysiwyg .home-grey .home-calendar table td.month_name {
  font-weight: normal;
  color: #1A519A;
}
main.page.front.wysiwyg .home-grey .home-calendar table a.em-calnav {
  color: #EF9501;
}
main.page.front.wysiwyg .home-grey .home-calendar table td.eventful {
  background-color: #5C5951;
}
main.page.front.wysiwyg .home-grey .home-calendar table td.eventful a {
  color: #fff;
}
main.page.front.wysiwyg .home-grey .home-calendar table.em-calendar td {
  padding: 12px 5px;
  border-bottom: 1px solid #D8D9D8;
}
main.page.front.wysiwyg .home-brown {
  background-color: #E6DFD5;
  padding: 50px 0px;
  position: relative;
  z-index: 0;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .home-brown h2 {
    padding: 0px 20px;
  }
}
main.page.front.wysiwyg .home-brown #member-list_wrapper {
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0px;
}
@media screen and (max-width: 640px) {
  main.page.front.wysiwyg .home-brown #member-list_wrapper {
    padding: 20px 0px;
  }
}
main.page.front.wysiwyg .home-brown::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #E6DFD5;
}
@media screen and (min-width: 1140px) {
  main.page.front.wysiwyg .home-brown::before {
    left: calc(-50vw + 1140px / 2);
  }
}
main.page.front.wysiwyg .home-brown h1 {
  padding-left: 10px;
}

table.meeting-location-table {
  background-color: #fff;
  margin-top: 20px;
  padding: 10px 0px;
  display: inline-block;
  border-radius: 10px;
  font-size: 11px;
}
table.meeting-location-table tr {
  width: 100%;
  display: inline-block;
  padding: 7px 10px;
}
table.meeting-location-table tr:nth-child(even) {
  background-color: #FAF9F7;
}

main.index .news-row, main.news-page .news-row, main.single .news-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 60px;
}
main.index .news-row .news-image, main.news-page .news-row .news-image, main.single .news-row .news-image {
  flex-basis: 25%;
}
@media screen and (max-width: 900px) {
  main.index .news-row .news-image, main.news-page .news-row .news-image, main.single .news-row .news-image {
    flex-basis: 100%;
  }
}
main.index .news-row .news-image img, main.news-page .news-row .news-image img, main.single .news-row .news-image img {
  max-width: 100%;
  height: auto;
}
main.index .news-row .news-content, main.news-page .news-row .news-content, main.single .news-row .news-content {
  flex-basis: 75%;
  padding-left: 20px;
}
@media screen and (max-width: 900px) {
  main.index .news-row .news-content, main.news-page .news-row .news-content, main.single .news-row .news-content {
    flex-basis: 100%;
  }
}
main.index .news-row .news-content h1, main.news-page .news-row .news-content h1, main.single .news-row .news-content h1 {
  font-size: 2rem;
  line-height: 3rem;
}
main.index .news-row .news-content h1 a, main.news-page .news-row .news-content h1 a, main.single .news-row .news-content h1 a {
  border: none;
  color: #1A519A;
  font-size: 2.5rem;
  line-height: 1rem;
}
main.index .news-row .news-content h1 a:hover, main.news-page .news-row .news-content h1 a:hover, main.single .news-row .news-content h1 a:hover {
  background-color: transparent;
}
main.index .news-row .news-content .excerpt, main.news-page .news-row .news-content .excerpt, main.single .news-row .news-content .excerpt {
  padding: 0;
}
main.index .post-meta, main.news-page .post-meta, main.single .post-meta {
  font-size: 0.8em;
}
main.index .post-meta span.post-date:before, main.news-page .post-meta span.post-date:before, main.single .post-meta span.post-date:before {
  content: '\f073';
  font-family: fontawesome;
  margin-right: 5px;
  color: #EF9501;
  font-size: 1.2em;
}
main.index .post-meta span.post-author, main.news-page .post-meta span.post-author, main.single .post-meta span.post-author {
  margin-left: 10px;
}
main.index .post-meta span.post-author:before, main.news-page .post-meta span.post-author:before, main.single .post-meta span.post-author:before {
  content: '\f007';
  font-family: fontawesome;
  margin-right: 5px;
  color: #EF9501;
  font-size: 1.2em;
}
main.index .post-meta span.post-comments, main.news-page .post-meta span.post-comments, main.single .post-meta span.post-comments {
  margin-left: 10px;
}
main.index .post-meta span.post-comments:before, main.news-page .post-meta span.post-comments:before, main.single .post-meta span.post-comments:before {
  content: '\f075';
  font-family: fontawesome;
  margin-right: 5px;
  color: #EF9501;
  font-size: 1.2em;
}

section.post-archive {
  background-color: #FAF8F5;
  padding-bottom: 80px;
  position: relative;
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
}
section.post-archive::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #FAF8F5;
}
@media screen and (min-width: 1140px) {
  section.post-archive::before {
    left: calc(-50vw + 1140px / 2);
  }
}
section.post-archive .archive-post {
  flex-basis: 25%;
  min-width: 200px;
  padding: 30px 20px;
  flex-grow: 1;
  max-width: 300px;
}
section.post-archive .archive-post a:hover {
  background-color: transparent;
}
section.post-archive .archive-post .archive-image {
  height: 175px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
section.post-archive .archive-post h2 {
  color: #4D4D4D;
  font-size: 1.6em;
  margin-top: 10px;
}
section.post-archive .archive-post span.post-date {
  color: #4D4D4D;
  font-size: 0.8em;
}
section.post-archive .archive-post span.post-date:before {
  content: '\f073';
  font-family: fontawesome;
  margin-right: 5px;
  color: #EF9501;
  font-size: 1.2em;
}
section.post-archive .archive-post p {
  color: #4D4D4D;
}
section.post-archive .archive-post a.read-more {
  width: 100%;
  margin-top: 10px;
  float: left;
  font-weight: bold;
}
section.post-archive .archive-post a.read-more:hover {
  color: #C07B2B;
}

.banner.single {
  margin-bottom: 60px;
}

main.single {
  padding-bottom: 80px;
}
@media screen and (max-width: 640px) {
  main.single {
    padding-top: 70px;
  }
}
html.touch main.single {
  padding-top: 70px;
}

.brown {
  margin-top: 60px;
  position: relative;
  z-index: 0;
  padding-bottom: 80px;
}
.brown::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #E6DFD5;
}
@media screen and (min-width: 1140px) {
  .brown::before {
    left: calc(-50vw + 1140px / 2);
  }
}
.brown .rotary-icon-brown {
  width: 100px;
  background-color: #E6DFD5;
  border-radius: 50px;
  padding: 10px;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}
.brown .rotary-icon-brown img {
  width: 80px;
  height: auto;
}
@media screen and (max-width: 640px) {
  .brown .rotary-icon-brown {
    display: none;
  }
}
.brown h2 {
  margin-top: 40px;
  display: inline-block;
  margin-left: 20px;
}
@media screen and (max-width: 640px) {
  .brown h2 {
    text-align: center;
  }
}

.rotary-files {
  border-radius: 10px;
  padding: 15px 20px;
  margin-top: 30px;
  display: inline-block;
  background-color: #fff;
  width: 100%;
}
.rotary-files .file-table-head {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #5C594F;
  margin-bottom: 10px;
}
.rotary-files .file-table-head h2 {
  font-size: 1.6em;
  text-align: left;
  margin-left: 0;
}
@media screen and (max-width: 640px) {
  .rotary-files .file-table-head h2 {
    display: none;
  }
}
.rotary-files a:before {
  content: '\f15c';
  font-family: fontawesome;
  margin-right: 10px;
}
.rotary-files hr {
  border-color: #E6DFD5;
  border-width: .5px;
  margin-top: 10px;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .rotary-files .pull-right {
    /* float: right; */
    float: left;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-left: 25px;
    width: 100%;
  }
}

main.search {
  padding-bottom: 40px;
}
main.search h1.search-title {
  margin-bottom: 40px;
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 0px;
}
main.search .search-form {
  padding-bottom: 80px;
}
main.search article.wysiwyg {
  margin-bottom: 40px;
  display: inline-block;
}
main.search article.wysiwyg h1 {
  font-size: 2rem;
}

.service-projects {
  margin-left: auto;
  margin-right: auto;
  max-width: 1140px;
  flex-basis: 100%;
  background-color: #FAF8F5;
  padding: 40px 0px 80px;
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 0;
}
.service-projects::before {
  z-index: -1;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #FAF8F5;
}
@media screen and (min-width: 1140px) {
  .service-projects::before {
    left: calc(-50vw + 1140px / 2);
  }
}
.service-projects .service-header {
  padding: 0px 20px;
}
.service-projects .service-header .grid-controls {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
.service-projects .service-header .grid-controls button {
  outline: none;
}
.service-projects .service-header .grid-controls .project-search {
  background-color: #E6DFD5;
  width: auto;
  height: 55px;
  padding: 0px 15px 5px;
  border-radius: 30px;
  margin-left: 0px;
  margin-top: 2px;
}
@media screen and (max-width: 640px) {
  .service-projects .service-header .grid-controls .project-search {
    margin-left: 0;
    margin-top: 20px;
  }
}
.service-projects .service-header .grid-controls .project-search input[type="text"] {
  border: none;
  outline: none;
}
.service-projects .service-header .grid-controls .project-search button {
  border: none;
  font-size: 1.5em;
  background-color: transparent;
  padding: 10px 5px;
  color: #5e5951;
}
.service-projects .grid .grid-item {
  padding: 15px 20px;
  width: 25%;
  float: left;
}
@media screen and (max-width: 970px) {
  .service-projects .grid .grid-item {
    width: 50%;
  }
}
@media screen and (max-width: 640px) {
  .service-projects .grid .grid-item {
    width: 100%;
  }
}
.service-projects .grid .grid-item a {
  padding: 0;
  color: #5D5951;
}
.service-projects .grid .grid-item a:hover .project-image .overlay {
  display: inline-block;
}
.service-projects .grid .grid-item a:hover .project-image img {
  display: inline-block;
}
.service-projects .grid .grid-item a .project-image {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  background-color: rgba #EF9501, 0.5;
}
.service-projects .grid .grid-item a .project-image .overlay {
  width: 100%;
  height: 150px;
  background-color: rgba(239, 149, 1, 0.5);
  display: none;
}
.service-projects .grid .grid-item a .project-image img {
  max-width: 100px;
  height: auto;
  margin-top: 20px;
  display: none;
}
.service-projects .grid .grid-item a h2 {
  font-size: 1.2em;
  color: #5e5951;
  margin-top: 10px;
}
.service-projects .grid .grid-item a p {
  font-family: 'Open Sans Condensed', sans-serif;
}

.wysiwyg .ui-tabs .ui-tabs-panel {
  padding: 0;
}
.wysiwyg #tabs {
  margin: 0;
  padding: 0;
}
.wysiwyg #tabs .tab-content {
  border: 1px solid #BAB5AD;
  border-top: none;
}
.wysiwyg #tabs .tab-content img {
  max-width: 100%;
  height: auto;
}
.wysiwyg #tabs .tab-content p {
  margin-top: 0;
  padding: 15px;
}
.wysiwyg #tabs .tab-content ul, .wysiwyg #tabs .tab-content ol {
  padding: 0px 15px;
}
.wysiwyg #tabs ul.ui-tabs-nav {
  display: flex;
  margin: 0;
  padding: 0;
}
.wysiwyg #tabs ul.ui-tabs-nav li {
  background-color: #E6DFD5;
  margin: 0;
  text-indent: 0;
  flex-grow: 1;
  text-align: center;
  border-bottom: 1px solid #BAB5AD;
  outline: none;
  max-width: 100%;
}
.wysiwyg #tabs ul.ui-tabs-nav li.ui-tabs-active {
  background-color: #fff;
  border: 1px solid #BAB5AD;
  border-top: 3px solid #EF9501;
  border-bottom: none;
}
.wysiwyg #tabs ul.ui-tabs-nav li:before {
  display: none;
}
.wysiwyg #tabs ul.ui-tabs-nav li a {
  outline: none;
  color: #5e5951;
  padding: 10px 20px;
  width: 100%;
  height: 40px;
  max-width: 100%;
  white-space: normal;
}
@media screen and (max-width: 640px) {
  .wysiwyg #tabs ul.ui-tabs-nav li a {
    padding: 10px 5px;
  }
}
.wysiwyg #tabs ul.ui-tabs-nav li a:hover, .wysiwyg #tabs ul.ui-tabs-nav li a:focus {
  background-color: transparent;
}

/**
 * Styles the WordPress login page
 */
body.login {
  background-color: #134282;
}
body.login div#login h1 a {
  background-image: url(../avatar.png);
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 300px;
  height: 100px;
}


