/**
 * Styles the front page
 */

.slider {
	background-color: #fff;

	@include respond-to(small) {
		padding-top: 50px;
	}

	.soliloquy-wrapper {
		max-height: 500px;
		overflow: hidden;
	}

	pre {
		display: none;
	}
	
	img.soliloquy-image {
		width: 100%;
	}
	
	.soliloquy-container {

		margin-bottom: 0px !important;

		 .soliloquy-caption {
			top: 50%;
			left: 10%;

			@media screen and (min-width: 1300px) {
				top: 30%;	
			}
			
			 .soliloquy-caption-inside {
				background: transparent;
				height: 100%;
				text-align: left;

				h1 {
		
					font-size: 3em;
					text-transform: uppercase;
					font-family: 'Open Sans Condensed', sans-serif;
					font-weight: bold;

					@media screen and (max-width: 900px) {
						font-size: 2rem;						
					}

					@include respond-to(small) {
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}

main.page.front.wysiwyg {
	position: relative;
	padding-top: 50px;
	padding-bottom: 0px;

	.rotary-icon-white {
		width: 100px;
		background-color: #fff ;
		border-radius: 50px;
		padding: 10px;
		position: absolute;
		top: -50px;
		left: calc(50% - 50px);

		img {
			width: 80px;
			height: auto;
		}

		@include respond-to(small) {
			display: none;
		}
	}

	
	.content {
		display: flex;
		padding-top: 60px;
		padding-bottom: 100px;
		
		h1 {
			margin: 0px 0px 45px 0px;
			font-size: 46px;
			text-transform: none;
		}
		
		h2 {
			margin: 15px 0px 12px 0px;
			color: $color-blue;
			font-family: 'Open Sans Condensed', sans-serif;
			font-weight: bold;
			font-size: 30px;
		}
		
		span {
			
			&.calendar,
			&.location {
				display: block;
				font-size: 18px;
				line-height: 28px;
				color: #4c4c4c;
				
				&:before {
					display: inline-block;
					margin-right: 5px;
					width: 20px;
					font-family: fontawesome;
					color: #1a519a;
					text-align: center;
				}
			}
			
			&.calendar {
				
				&:before {
					content: "\f133";
				}
			}
			
			&.location {
				
				&:before {
					content: "\f041";
				}
			}
		}
		
		p {
			margin-bottom: 25px;
			font-size: 16px;
			line-height: 26px;
			color: #4c4c4c;
		}
		
		&.grey {
			@include full-width {
				background-color: $color-bg-grey;
			}
		}
		
		&.brown {

		

			@include full-width {
				background-color: $color-bg-brown;
			}
			
			ul {
				
				li {
					display: inline-block;
					margin-right: 20px;
					color: #595959;
					font-size: 18px;
					line-height: 36px;
				}
			}
		}

		section {
			
			&.week,
			&.news,
			&.largest,
			&.events {
				flex-basis: 50%;
			}
		}
	}

	.rotary-icon-grey{
		width: 100px;
		background-color: #F5F5F5;
		border-radius: 50px;
		padding: 10px;
		position: absolute;
		top: -50px;
		left: calc(50% - 50px);


		img {
			width: 80px;
			height: auto;
		}

		@include respond-to(small) {
			display: none;
		}
	}

	.rotary-icon-brown{
		width: 100px;
		background-color: $color-bg-brown;
		border-radius: 50px;
		padding: 10px;
		position: absolute;
		top: -50px;
		left: calc(50% - 50px);


		img {
			width: 80px;
			height: auto;
		}

		@include respond-to(small) {
			display: none;
		}
	}



	.column-2 {
		// @include flex-column(2, 20px);
		flex-basis: 18.75rem;
		flex-grow: 1;
		padding: 0px 20px;

		@include respond-to(small) {
			padding-bottom: 60px;
		}
	}

	.row { 
		display: flex;
		flex-flow: wrap row;
	}

	.event-preview {


		h3{
			margin-bottom: 20px;
		}

		.event-single {
			width: 100%;
			float: left;
			display: flex;
			margin-bottom: 20px;
			background-color: transparent;

			&:active {
				background-color: transparent;
				color: $color-orange;
			}

			&:focus {
				background-color: transparent;
				color: $color-orange;
			}

			a {

				display: block;
				background-color: transparent;

				&:hover {
					background-color: transparent;
					color: $color-orange;
				} 
			}

			.event-date {
				width: 50px;
				height: 55px;
				padding-top: 2px;
				margin-right: 15px;
				background-color: #DAEAE7;
				color: #5D5952;
				
				text-align: center;
				text-transform: uppercase;

				.day {
					width: 100%;
					font-weight: bold;
					font-size: 1.8em;
					display: inline-block;
					line-height: 1em;
				}

				.month {
					width: 100%;
					display: inline-block;
					line-height: 1em;
				}
			}

			.event-info {
				small {
					a {
						display: inline-block;
					}
				}
			}

			h2 {
				font-size: 1.2em;
			}
		}

			// ul {
			// 	margin: 0;
			// }
		
			// li {
			// 	list-style: none;
			// }

			// img {
			// 	width: 33%;
			// 	height: auto;
			// 	margin: 20px 0px;
			// }

			// p.event-date {

			// 	margin: 5px 0px;
			// 	color: $color-light-grey;

			// 	&:before {
			// 		content: '\f133';
			// 		font-family: fontawesome;
			// 		color: $color-blue;
			// 		margin-right: 5px;
			// 	}
			// }

			// p.event-location {

			// 	margin:5px 0px;
			// 	color: $color-light-grey;

			// 	&:before {
			// 		content: '\f041';
			// 		font-family: fontawesome;
			// 		color: $color-blue;
			// 		margin-right: 5px;
			// 	}
			// }

			// .event-description p {
			// 	margin: 5px 0px 20px 0px;
			// }
		
	}

	.blog-posts, .this-week {

		a.read-more {
			display: none;
		}
		.post-preview {
			margin-bottom: 40px;

			img {
				width: 33%;
				height: auto;
				margin-top: 20px;
				margin-bottom: 20px;

			@include respond-to(small) {
				width:100%;
			}

			}
			a.read-article {
				width: 100%;
				text-transform: uppercase;
				font-family: 'Open Sans Condensed', sans-serif;
				font-weight: bold;
				color: $color-orange;

				&:hover {
					color: #fff;
				}

				&:after {
					content: '\f178';
					font-family: fontawesome;
					color: $color-light-grey;
					margin-left: 10px;
				}

			}

			&:nth-child(3), &:nth-child(4) {

				width: 50%;
				float: left;
				padding-right: 20px;
				min-width: 200px;

				@include respond-to(small) {
					width: 100%;
				}

				img { 
					display: none;
				}
				p {
					display: none;
				}
				h2 {
					font-size: 1.5em;
				}
			}
		}
	}

	.home-grey {
		background-color: #F5F5F5;
		padding: 50px 0px;
		margin-top: 50px;

		@include full-width {
			background-color: #F5F5F5;
		}

		.home-section {
			img {
				width: 50%;
				height: auto;
				margin: 20px 0px;
			}
		}

		.home-calendar {

			li.widget {
				list-style: none;
			}
			table {
				width: 100%;
				margin-top: 20px;

				td.month_name {
					font-weight: normal;
					color: #1A519A;
				}

				a.em-calnav {
					color: $color-orange;
				}

				td.eventful {
					background-color: #5C5951;

					a {
						color: #fff;
					}
				}
			}

			table.em-calendar td {
				padding: 12px 5px;
				border-bottom: 1px solid #D8D9D8;
			}
		}
	}

	.home-brown {
		background-color: $color-bg-brown;
		padding: 50px 0px;

		h2 {
			@include respond-to(small) {
				padding: 0px 20px;
			}
		}

		#member-list_wrapper {
			padding: 20px;
			border-radius: 10px;
			margin: 20px 0px;

			@include respond-to(small) {
				padding: 20px 0px;
			}
		}

		@include full-width {
			background-color: $color-bg-brown;
		}

		h1 {
			padding-left: 10px;
		}

	}
}
