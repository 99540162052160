// Gecko Modal class
dialog {
	display: block;
	position: fixed;
	z-index: 50;

	transition: top 0.5s ease-in-out, visibility 0s linear 0.5s;
	visibility: hidden;
	left: 0;
	right: 0;
	top: -100%;

	&[open] {
		transition-delay: 0s;
		visibility: visible;
		top: 100px;
	}

	.close::before {
		content: "\f00d";
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px;
		cursor: pointer;
		color: rgba(255, 255, 255, 0.5);
	}
}