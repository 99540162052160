/**
 * Site color definitions
 * Includes both name-specific and semantic names
 */

// Colors by name
$color-blue: #1A519A;
$color-orange: #EF9501;
$color-dark-orange: #C07B2B;
$color-aqua: #D7E9E7;
$color-light-grey: #BAB5AD;
$color-dark-grey: #5D5951;
$color-bg-grey: #F5F5F5;
$color-bg-brown: #E6DFD5;
$color-black: #4D4D4D;

// Semantic colors
$color-content-text: black;
$color-content: white;

$color-interactive: $color-blue;
$color-interactive-text: white;

$color-active: lighten($color-interactive, 10%);

$color-highlight: $color-blue;
$color-highlight-text: white;