


.contact-page {

	.contact-left {
		width: 50%;
		float: left;
		padding-left: 20px;

		@include respond-to(small) {
			width: 100%;
			padding: 10px 15px; 
		}

		a.telephone {
			font-size: 2em; 
			font-weight: bold;
			color: #1A519A;

			&:hover {
				background-color: transparent;
			}

			&:before {
				content: '\f098';
				font-family: fontawesome;
				margin-right: 10px;
				color: #EF9501;
			}
		}
	}
	.contact-right {
		width: 50%;
		float: left;
		display: inline-block;

		@include respond-to(small) {
			width: 100%;
			padding: 10px 15px; 
		}

		form.contact-form {

			position: relative;
			z-index: 20;

			ul{
				display: flex;
				flex-wrap: wrap;


				li {
					padding-left: 0;
					padding-right: 20px;
					text-indent: 0;

					label {
						margin-bottom: 0px;
						font-weight: normal;
					}

					input {
						width: 100%;
						margin: 0px;
						outline: none;
					}

					textarea {
						width: 100%;
						margin: 0;
						height: 200px;
						padding: 10px 10px 80px 10px;
						outline: none;
						background-color: #fff;
					}
				}

				li#field_1_1 {
					width: 50%;

					@include respond-to(small) {
						width: 100%;
					}
				}

				li#field_1_2 {
					width: 50%;
					
					@include respond-to(small) {
						width: 100%;
					}
				}
				li#field_1_3 {
					width: 100%;
				}
			}

			.gform_footer {
				margin: 0;
				padding: 0;
				position: relative;

				input.button {
					position: absolute;
					top: -85px;
					right: 30px;

					@include respond-to(small) {
						position: relative;
						top: 0;
						left: 0;
					}
				}

			}
		}
	}
}

.maps {
	display: inline-block;
	width: 100%;
	margin-top: -140px;

	@include respond-to(small) {
		margin-top: 20px;
	}
}

.maps iframe{
    pointer-events: none; 

}