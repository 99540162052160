

.brown {

	margin-top: 60px;


	@include full-width {
		background-color: $color-bg-brown;
	}
	padding-bottom: 80px;

	.rotary-icon-brown{
		width: 100px;
		background-color: $color-bg-brown;
		border-radius: 50px;
		padding: 10px;
		position: absolute;
		top: -50px;
		left: calc(50% - 50px);


		img {
			width: 80px;
			height: auto;
		}

		@include respond-to(small) {
			display: none;
		}
	}

	h2 {
		margin-top: 40px;
		display: inline-block;
		margin-left: 20px;

		@include respond-to(small) {
			text-align: center;
		}
	}
}

.rotary-files {

	// border: 1px solid #5C594F;
	border-radius: 10px;
	padding: 15px 20px;
	margin-top: 30px;
	display: inline-block;
	background-color: #fff;
	width: 100%;


	.file-table-head {
		display: flex;
		justify-content: space-between;
		border-bottom: 2px solid #5C594F;
		margin-bottom: 10px;

		h2{
			font-size: 1.6em;
			text-align: left;
			margin-left: 0;

			@include respond-to(small) {
				display: none;
			}
		}
	}

	a {
		&:before {
			content: '\f15c';
			font-family: fontawesome;
			margin-right: 10px;
		}
	}

	hr {
		border-color: #E6DFD5;
		border-width: .5px;
		margin-top: 10px;
		width: 100%;
	}

	@include respond-to(small) {
		.pull-right {
		    /* float: right; */
		    float: left;
		    margin-top: 5px;
		    margin-bottom: 10px;
		    padding-left: 25px;
		    width: 100%;
		}
	}
}