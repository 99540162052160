/**
 * Site-wide constants
 */

$base-font-size: 16px;
$type-scale: 1.414;

// The width of the site
$site-width: 1140px;

// The width that the navigation should break at
$mobile-nav-width: 640px;

// Selectors for inputs
$buttons: "%button, button, a.button, input[type=button], input[type=submit]";
$text-inputs: "%text-input, textarea, input[type=text], input[type=email], input[type=password], input[type=tel]";