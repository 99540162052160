/**
 * Content styling
 * Styles the body of all pages
 */

.banner {
		position: relative;
		margin-bottom: 80px;
		height: 300px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		@include respond-to(small) {
			padding-top: 50px;
		}
		
		h1 {
			position: absolute;
			bottom: -30px;
			left: 0;
			display: inline-block;
			margin: 0px;
			padding: 0px 40px;
			font-size: 35px;
			font-weight: bold;
			line-height: 70px;
			color: #5e5951;
			text-transform: uppercase;
			background-color: $color-bg-brown;

			@include respond-to(small) {
				font-size: 30px;
				width: 100%;
				padding: 20px;
				text-align: center;
				line-height: 1.2em;
				bottom: -20px;
			}
		}

		.row {
			@include clamped-width;
			position: relative;
			height: 300px;


		}
	}

main {

	background-color: #fff;
	

	@include clamped-width;
	
	.content {

		padding: 0px 20px;
		
		// h1 {
		// 	font-size: 1.7em;
		// 	color: $color-blue;
		// 	font-family: 'Open Sans Condensed', sans-serif;
		// 	font-weight: bold;
		// 	text-transform: uppercase;
		// }

		@include clamped-width;

		.row {
			@include flex-row;
		}
	}

	p {
		font-weight: lighter;
		line-height: 1.6em;
	}
}

.column-wrapper {

	display: flex;
	flex-wrap: wrap;
	padding-bottom: 60px;

	.page-left-col {
		flex-basis: 60%;
		flex-grow: 1;
		padding: 0px 20px;
		margin: 0;

		@include respond-to(small) {
			flex-basis: 100%;
			width: 100%;
		}



		img {
			width: 100%;
			max-width: 100%;
			height: auto;
		}
	}

	.page-right-col {
		flex-basis: 40%;
		padding: 0px 20px;
		flex-grow: 1;

		@include respond-to(small) {
			flex-basis: 100%;
			width: 100%;
		}

	}
}

body.single {

	.wysiwyg {

		h1 {
			font-size: 2.5rem;
		}
	}
}

