/**
 * Styles the desktop header on all pages
 */

body > header.desktop {

	background-color: #fff;
	
	.top-menu,
	.main-header {
		@include clamped-width;
	}

	@include with-touch-nav {
		display: none;
	}

	.top-menu {
		width: 100%;
		padding: 10px ;
		display: flex;
		flex-direction: row-reverse;
		font-weight: lighter;

		@include full-width {
			background-color: $color-blue;
		}

		background-color: $color-blue;
		position: relative;

		a {
			color: $color-aqua; 

			&:hover {
				color: #fff;
			}
		}

		.menu {

			li {
				display: inline-block;
				margin-left: 20px;
				font-size: .8em;

				@include respond-to(medium) {
					margin-left: 10px;
				}

				i {
					color: $color-light-grey;
					font-size: 1.1em;
				}
			}

			li.give {

				a {
					color: $color-orange;
					text-transform: uppercase;
					font-weight: bold;

					i {
						color:  $color-orange;
					}

				&:hover {
					i {
						font-size: 1.3em;
						margin-left: -2.5px
					}
				}

				}
			}
			
			.no-text {

				span.fontawesome-text {
					display: none;
				}

				i {
					font-size: 1.3em;

					&:hover {
						color: #fff;
					}
				}
			}
		}
	}

	.main-header {

		display: flex;
		
		justify-content: space-between;
		padding: 10px 10px 0px 10px;

		@include respond-to(medium) {
			flex-wrap: wrap;
		}

		a.logo {
			
			height: auto;
			display: inline-block;


			@include respond-to(medium) {
				flex-basis: 100%;
				text-align: center;

				
			
			}

			img {
				max-width: 200px;
				width: 100%;
				height: auto;
				flex-shrink: 0;
				flex-grow: 0;


				@include respond-to(medium) {
						max-width: 200px;
						height: auto;
					}
			}
		}
	}

	nav {
		background-color: #fff;
		color: white;
		margin: 0 ;
		font-family: 'Open Sans Condensed', sans-serif;
		font-weight: bold;
		text-transform: uppercase;
		flex-basis: 100%;
		padding-left: 30px;

		@include respond-to(medium) {
			padding-left: 0;
		}

		ul {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		ul > li {
			display: inline-block;
			text-align: center;

			&:last-child {
				a {
					padding-right: 0px; 
				}
			}
			
			&.current-menu-item,
			&.current_page_item {
				
				> a {
					border-bottom: 5px solid $color-dark-grey;
					padding-bottom: 35px;
					color: $color-dark-grey;
				}
			}
			
			> a {
				display: block;
				padding: 35px 15px 40px 15px;
				color: $color-blue;

				@include respond-to(medium) {

					padding: 35px 5px 40px 5px;
				}


				&:hover {
					border-bottom: 5px solid $color-dark-grey;
					padding-bottom: 35px;
					color: $color-dark-grey;
				}
			}

			> .sub-menu {
				display: none;
			}
		}
	}
}