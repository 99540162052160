.service-projects {

	@include clamped-width;
	flex-basis: 100%;
	background-color: #FAF8F5;
	padding: 40px 0px 80px;
	display: inline-block;
	width: 100%;  

	@include full-width {
		background-color: #FAF8F5;
	}

	.service-header {

		padding: 0px 20px;

		.grid-controls {
			margin-bottom: 20px;
			margin-top: 20px;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: space-between;

			button {
				outline: none;
			}

			.project-search {
				background-color: #E6DFD5;
				width: auto;
				height: 55px;
				padding: 0px 15px 5px;
				border-radius: 30px;
				margin-left: 0px; 
				margin-top: 2px;

				@include respond-to(small) {
					margin-left: 0;
					margin-top: 20px;
				}

				input[type="text"] {
					border: none;
					outline: none;
				}

				button {
					border: none;
					font-size: 1.5em;
					background-color: transparent;
					padding: 10px 5px;
					color: #5e5951;
				}
			}
		}
	}

	.grid {

		.grid-item {
			padding: 15px 20px;
			width: 25%; 
			float: left;

			@include respond-to(medium) {
				width: 50%;
			}

			@include respond-to(small) {
				width: 100%;
			}

			a {
				padding: 0;
				color: $color-dark-grey;

				&:hover {

					.project-image {

						.overlay {
							display: inline-block;
						}

						
						img {
							display: inline-block;
						}
					}
				}

				.project-image {

					width: 100%;
					height: 150px;
					background-size: cover;
					background-repeat: no-repeat;
					text-align: center;
					background-color: rgba ($color-orange, 0.5);

					.overlay {
						width: 100%;
						height: 150px;
						background-color: rgba($color-orange, 0.5);
						display: none;
					}

					img {
						max-width: 100px;
						height: auto;
						margin-top: 20px;
						display: none;

					}
				}

				h2 {
					font-size: 1.2em;
					color: #5e5951;
					margin-top: 10px;

				}

				p {
					font-family: 'Open Sans Condensed', sans-serif;
				}
			}
		}
	}
}


%wysiwyg, .wysiwyg { 

	.ui-tabs .ui-tabs-panel {
		padding: 0;
	}

	#tabs {
			
		margin: 0;
		padding: 0;

		.tab-content {
			border: 1px solid $color-light-grey;
			border-top: none;

			img {
				max-width: 100%;
				height: auto;
			}

			p {
				margin-top: 0;
				padding: 15px;
			}

			ul, ol {
				padding: 0px 15px;
			}
		}

		ul.ui-tabs-nav {
			display: flex;
			margin: 0;
			padding: 0;

			li {
				background-color: #E6DFD5;
				margin: 0; 
				text-indent: 0;
				flex-grow: 1;
				text-align: center;
				border-bottom: 1px solid $color-light-grey;
				outline: none;
				max-width: 100%;

				&.ui-tabs-active {
					
					background-color: #fff;
					border: 1px solid $color-light-grey;
					border-top: 3px solid $color-orange;
					border-bottom: none;
				}

				&:before {
					display: none;
				}

				a {
					outline: none;
					color: #5e5951;
					padding: 10px 20px;
					width: 100%;
					height: 40px;
					max-width: 100%;
					white-space: normal;

					@include respond-to(small) {
						padding: 10px 5px;
					}

					&:hover, &:focus {
						background-color: transparent;
					}
				}
			}
		}
	}
}