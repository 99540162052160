@mixin flex-row {
	display: flex;
	flex-wrap: wrap;
}

@mixin flex-column($size: 100%, $margin: 0.4rem) {
	@if (unit($size) == "") {
		@if (floor($size) == $size) {
			@include flex-column(100% / $size, $margin);
		} @else {
			@include flex-column($size * 100%, $margin);
		}
	} @else {
		flex-basis: calc(#{$size} - #{$margin * 2});
		margin: $margin;
	}
}