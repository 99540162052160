main.search {

	padding-bottom: 40px; 

	h1.search-title {
		margin-bottom: 40px;
		font-size: 1.5em;
		font-weight: bold;
		padding-left: 0px;
	}

	.search-form {
		padding-bottom: 80px;
	}

	article.wysiwyg {

		margin-bottom: 40px;
		display: inline-block;

		h1 {
			font-size: 2rem;
		}
	}
}